import React from 'react';
import { StyledButton } from './ButtonsStyles';

const Button = ( props ) => {
  const { buttonText, ...other } = props;
  return (
    <StyledButton {...other}>
      { buttonText }
    </StyledButton>
  )
};

export default Button;

// We can use the "as" polymorphic prop as described here https://styled-components.com/docs/basics#extending-styles
