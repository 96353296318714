import styled from 'styled-components';

export const GigsSection = styled.section`
  padding: 24px 0;
  color: ${props => props.color === "lightGrey" ? ({ theme }) => theme.colors.lightGrey : ({ theme }) => theme.colors.sapphireBlue};

  @media (min-width: 768px) {
    padding: 48px 0;
  }

  h1 {
    margin-bottom: 40px;
  }

  h2 {
    margin-bottom: 24px;
    text-align: center;
  }

  ul {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    list-style-type: none;
  }

  li {
    margin-bottom: 60px;
    position: relative;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }

    p {
      margin-bottom: 8px;
    }

    &:not(:first-child) {

      div::after {
        content: '';
        display: block;
        width: 50px;
        height: 2px;
        margin: 0 auto;
        background-color: ${props => props.color === "lightGrey"  ? ({ theme }) => theme.colors.lightGrey : ({ theme }) => theme.colors.sapphireBlue};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 120%;

        @media (min-width: 768px) {
          bottom: 125%;
        }
      }
    }

    h3 {
      margin-bottom: 8px;
    }

  }

  li:last-of-type {
    margin-bottom: 40px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }

  .upcoming-message {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    @media (max-width: 767px) {
      line-height: 1.6;
    }
  }
`;

export const VideoSection = styled.section`
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  padding: 24px 0;

  h1 {
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 32px;
  }

  iframe {
    height: 240px;

    @media (min-width: 768px) {
      height: 300px;
      width: 410px;
    }
  }
`;
