import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useMediaQuery } from 'react-responsive';

import { renderGigs } from '../utils/utils';
import { fetchGigs } from '../utils/api';

import { Container } from 'react-bootstrap';
import { GigsSection, VideoSection } from '../styles/gigPageStyles';
import Video from "../components/Video/video"
import Button from '../components/Button/Button';

const GigsPage = ( { data } ) => {
  const { gigVideo, gigVideoTitle } = data.contentfulGigPage;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  const [allUpcomingGigs, setAllUpcomingGigs] = useState([]);
  const [allPastGigs, setAllPastGigs] = useState([]);

  useEffect(() => {
    fetchGigs()
      .then(resultData => {
        // Get Eastern time
        const torontoDateToday = new Date().toLocaleString('en-US', {timeZone: "America/New_York", year: 'numeric', month: 'numeric', day: 'numeric'});

				// convert from M/D/Y to M-D-Y
        const convertToISODate = ( date ) => {
          return new Date(date).toISOString().split('T')[0];
        }

				const allUpcomingGigs = resultData.data.items
					.filter(item => (item.fields.gigDate) >= convertToISODate(torontoDateToday))
					.sort((a, b) => new Date(a.fields.gigDate) - new Date(b.fields.gigDate));

        const allPastGigs = resultData.data.items
					.filter(item => (item.fields.gigDate) < convertToISODate(torontoDateToday))
          .sort((a, b) => new Date(b.fields.gigDate) - new Date(a.fields.gigDate));;

        setAllUpcomingGigs(allUpcomingGigs);
        setAllPastGigs(allPastGigs);
      })
  }, [])

  // View more past gigs
  const [pastGigsToShow, setPastGigsToShow] = useState(2);
  const [upcomingGigsToShow, setupcomingGigsToShow] = useState(2);
  const pastGigs = allPastGigs.slice(0, pastGigsToShow);
  const upcomingGigs = allUpcomingGigs.slice(0, upcomingGigsToShow);

  const viewMorePastGigs = () => {
    setPastGigsToShow( pastGigsToShow + 5 );
  };

  const viewMoreUpcomingGigs = () => {
    setupcomingGigsToShow(upcomingGigsToShow + 5);
  };

  const renderViewMore = ( gigsToShow, allGigsLength, buttonText, clickHandler ) => {
    const props = { buttonText: buttonText, onClick: clickHandler };
    if (gigsToShow.length < allGigsLength.length) {
      return (
        <Button {...props} />
      )
    }
  }

  const renderGigsPage = () => {
    if (isTabletOrMobile) {
      return (
        <>
          <VideoSection>
            <Container>
              <h1>Gigs</h1>
              <Video
                videoSrcURL={gigVideo}
                videoTitle={gigVideoTitle}
              />
            </Container>
          </VideoSection>
          <GigsSection lightGrey>
            <Container>
            <h2>Upcoming Gigs</h2>
              {renderGigs(upcomingGigs)}
              <div className="buttonContainer">
                {renderViewMore(upcomingGigs, allUpcomingGigs, 'View More', viewMoreUpcomingGigs)}
              </div>
            </Container>
          </GigsSection>
          <hr style={{ marginTop: "0", marginBottom: "16px" }} />
          <GigsSection color="lightGrey">
            <Container>
              <h2>Past Gigs</h2>
                {renderGigs(pastGigs)}
                <div className="buttonContainer">
                  {renderViewMore(pastGigs, allPastGigs, 'View More', viewMorePastGigs)}
                </div>
            </Container>
          </GigsSection>
        </>
      )
    } else {
      return (
        <>
          <GigsSection>
            <Container>
            <h1>Gigs</h1>
            <h2>Upcoming Gigs</h2>
              {renderGigs(upcomingGigs)}
              <div className="buttonContainer">
                {renderViewMore(upcomingGigs, allUpcomingGigs, 'View More', viewMoreUpcomingGigs)}
              </div>
            </Container>
          </GigsSection>

          <VideoSection>
            <Container className="d-flex justify-content-center">
              <Video
                videoSrcURL={gigVideo}
                videoTitle={gigVideoTitle}
              />
            </Container>
          </VideoSection>

          <GigsSection color="lightGrey">
            <Container>
              <h2>Past Gigs</h2>
                {renderGigs(pastGigs)}
                <div className="buttonContainer">
                  {renderViewMore(pastGigs, allPastGigs, 'View More', viewMorePastGigs)}
                </div>
            </Container>
          </GigsSection>
        </>
      )
    }
  };

  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return(
    <Layout>
      <SEO title="Home" />
        {renderGigsPage()}
    </Layout>
  )
}

export default GigsPage;

export const pageQuery = graphql`
  query gigsPageQuery {
    contentfulGigPage {
      gigVideo
      gigVideoTitle
    }
  }
`
